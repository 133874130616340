export const COUNTRIESLIST = [
  {
    code: 'AF',
    label: 'Afghanistan',
    capital: 'Kabul',
    phone: '93',
    location: { lat: 33.9391, lng: 67.71 },
  },
  {
    code: 'AL',
    label: 'Albania',
    capital: 'Tirana',
    phone: '355',
    location: { lat: 41.3275, lng: 19.8187 },
  },
  {
    code: 'DZ',
    label: 'Algeria',
    capital: 'Algiers',
    phone: '213',
    location: { lat: 36.7373, lng: 3.0868 },
  },
  {
    code: 'AD',
    label: 'Andorra',
    capital: 'Andorra la Vella',
    phone: '376',
    location: { lat: 42.5063, lng: 1.5218 },
  },
  {
    code: 'AO',
    label: 'Angola',
    capital: 'Luanda',
    phone: '244',
    location: { lat: -8.8116, lng: 13.242 },
  },
  {
    code: 'AQ',
    label: 'Antarctica',
    capital: 'McMurdo Station',
    phone: '',
    location: { lat: -77.8463, lng: 166.6682 },
  },
  {
    code: 'AR',
    label: 'Argentina',
    capital: 'Buenos Aires',
    phone: '54',
    location: { lat: -34.6037, lng: -58.3816 },
  },
  {
    code: 'AM',
    label: 'Armenia',
    capital: 'Yerevan',
    phone: '374',
    location: { lat: 40.1872, lng: 44.5152 },
  },
  {
    code: 'AW',
    label: 'Aruba',
    capital: 'Oranjestad',
    phone: '297',
    location: { lat: 12.5209, lng: -69.9683 },
  },
  {
    code: 'AU',
    label: 'Australia',
    capital: 'Canberra',
    phone: '61',
    location: { lat: -35.282, lng: 149.1286 },
  },
  {
    code: 'AT',
    label: 'Austria',
    capital: 'Vienna',
    phone: '43',
    location: { lat: 48.2082, lng: 16.3738 },
  },
  {
    code: 'AZ',
    label: 'Azerbaijan',
    capital: 'Baku',
    phone: '994',
    location: { lat: 40.4093, lng: 49.8671 },
  },
  {
    code: 'BS',
    label: 'Bahamas',
    capital: 'Nassau',
    phone: '1-242',
    location: { lat: 25.0343, lng: -77.3963 },
  },
  {
    code: 'BH',
    label: 'Bahrain',
    capital: 'Manama',
    phone: '973',
    location: { lat: 26.2285, lng: 50.586 },
  },
  {
    code: 'BD',
    label: 'Bangladesh',
    capital: 'Dhaka',
    phone: '880',
    location: { lat: 23.8103, lng: 90.4125 },
  },
  {
    code: 'BB',
    label: 'Barbados',
    capital: 'Bridgetown',
    phone: '1-246',
    location: { lat: 13.0975, lng: -59.6168 },
  },
  {
    code: 'BY',
    label: 'Belarus',
    capital: 'Minsk',
    phone: '375',
    location: { lat: 53.7098, lng: 27.9534 },
  },
  {
    code: 'BE',
    label: 'Belgium',
    capital: 'Brussels',
    phone: '32',
    location: { lat: 50.8503, lng: 4.3517 },
  },
  {
    code: 'BZ',
    label: 'Belize',
    capital: 'Belmopan',
    phone: '501',
    location: { lat: 17.2534, lng: -88.7713 },
  },
  {
    code: 'BJ',
    label: 'Benin',
    capital: 'Porto-Novo',
    phone: '229',
    location: { lat: 6.4779, lng: 2.6323 },
  },
  {
    code: 'BM',
    label: 'Bermuda',
    capital: 'Hamilton',
    phone: '1-441',
    location: { lat: 32.2949, lng: -64.7839 },
  },
  {
    code: 'BT',
    label: 'Bhutan',
    capital: 'Thimphu',
    phone: '975',
    location: { lat: 27.4728, lng: 89.639 },
  },
  {
    code: 'BO',
    label: 'Bolivia',
    capital: 'La Paz',
    phone: '591',
    location: { lat: -16.5, lng: -68.15 },
  },
  {
    code: 'BQ',
    label: 'Bonaire, Sint Eustatius and Saba',
    capital: 'Kralendijk',
    phone: '599',
    location: { lat: 12.1784, lng: -68.2385 },
  },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    capital: 'Sarajevo',
    phone: '387',
    location: { lat: 43.8563, lng: 18.4131 },
  },
  {
    code: 'BW',
    label: 'Botswana',
    capital: 'Gaborone',
    phone: '267',
    location: { lat: -24.658, lng: 25.9121 },
  },
  {
    code: 'BR',
    label: 'Brazil',
    capital: 'Brasília',
    phone: '55',
    location: { lat: -15.7801, lng: -47.9292 },
  },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
    capital: 'Diego Garcia',
    phone: '246',
    location: { lat: -6.3432, lng: 71.8765 },
  },
  {
    code: 'VG',
    label: 'British Virgin Islands',
    capital: 'Road Town',
    phone: '1-284',
    location: { lat: 18.4207, lng: -64.6399 },
  },
  {
    code: 'BN',
    label: 'Brunei Darussalam',
    capital: 'Bandar Seri Begawan',
    phone: '673',
    location: { lat: 4.9031, lng: 114.939 },
  },
  {
    code: 'BG',
    label: 'Bulgaria',
    capital: 'Sofia',
    phone: '359',
    location: { lat: 42.6977, lng: 23.3219 },
  },
  {
    code: 'BF',
    label: 'Burkina Faso',
    capital: 'Ouagadougou',
    phone: '226',
    location: { lat: 12.3714, lng: -1.5197 },
  },
  {
    code: 'BI',
    label: 'Burundi',
    capital: 'Bujumbura',
    phone: '257',
    location: { lat: -3.3818, lng: 29.3622 },
  },
  {
    code: 'KH',
    label: 'Cambodia',
    capital: 'Phnom Penh',
    phone: '855',
    location: { lat: 11.5564, lng: 104.9282 },
  },
  {
    code: 'CM',
    label: 'Cameroon',
    capital: 'Yaoundé',
    phone: '237',
    location: { lat: 3.848, lng: 11.5021 },
  },
  {
    code: 'CA',
    label: 'Canada',
    capital: 'Ottawa',
    phone: '1',
    location: { lat: 45.4215, lng: -75.6919 },
  },
  {
    code: 'CV',
    label: 'Cabo Verde',
    capital: 'Praia',
    phone: '238',
    location: { lat: 14.933, lng: -23.5133 },
  },
  {
    code: 'KY',
    label: 'Cayman Islands',
    capital: 'George Town',
    phone: '1-345',
    location: { lat: 19.3133, lng: -81.2546 },
  },
  {
    code: 'CF',
    label: 'Central African Republic',
    capital: 'Bangui',
    phone: '236',
    location: { lat: 4.375, lng: 18.7221 },
  },
  {
    code: 'TD',
    label: 'Chad',
    capital: "N'Djamena",
    phone: '235',
    location: { lat: 12.1347, lng: 15.0557 },
  },
  {
    code: 'CL',
    label: 'Chile',
    capital: 'Santiago',
    phone: '56',
    location: { lat: -33.4475, lng: -70.6734 },
  },
  {
    code: 'CN',
    label: 'China',
    capital: 'Beijing',
    phone: '86',
    location: { lat: 39.9042, lng: 116.4074 },
  },
  {
    code: 'CX',
    label: 'Christmas Island',
    capital: 'Flying Fish Cove',
    phone: '61',
    location: { lat: -10.4475, lng: 105.6904 },
  },
  {
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
    capital: 'West Island',
    phone: '61',
    location: { lat: -12.1642, lng: 96.8701 },
  },
  {
    code: 'CO',
    label: 'Colombia',
    capital: 'Bogotá',
    phone: '57',
    location: { lat: 4.711, lng: -74.0721 },
  },
  {
    code: 'KM',
    label: 'Comoros',
    capital: 'Moroni',
    phone: '269',
    location: { lat: -11.7172, lng: 43.2479 },
  },
  {
    code: 'CG',
    label: 'Congo (Brazzaville)',
    capital: 'Brazzaville',
    phone: '242',
    location: { lat: -4.2634, lng: 15.2429 },
  },
  {
    code: 'CD',
    label: 'Congo (Kinshasa)',
    capital: 'Kinshasa',
    phone: '243',
    location: { lat: -4.4419, lng: 15.2663 },
  },
  {
    code: 'CK',
    label: 'Cook Islands',
    capital: 'Avarua',
    phone: '682',
    location: { lat: -21.2367, lng: -159.7777 },
  },
  {
    code: 'CR',
    label: 'Costa Rica',
    capital: 'San José',
    phone: '506',
    location: { lat: 9.9281, lng: -84.0907 },
  },
  {
    code: 'HR',
    label: 'Croatia',
    capital: 'Zagreb',
    phone: '385',
    location: { lat: 45.815, lng: 15.9819 },
  },
  {
    code: 'CU',
    label: 'Cuba',
    capital: 'Havana',
    phone: '53',
    location: { lat: 23.1136, lng: -82.3666 },
  },
  {
    code: 'CW',
    label: 'Curaçao',
    capital: 'Willemstad',
    phone: '599',
    location: { lat: 12.1696, lng: -68.99 },
  },
  {
    code: 'CY',
    label: 'Cyprus',
    capital: 'Nicosia',
    phone: '357',
    location: { lat: 35.1856, lng: 33.3823 },
  },
  {
    code: 'CZ',
    label: 'Czech Republic',
    capital: 'Prague',
    phone: '420',
    location: { lat: 50.0755, lng: 14.4378 },
  },
  {
    code: 'DK',
    label: 'Denmark',
    capital: 'Copenhagen',
    phone: '45',
    location: { lat: 55.6761, lng: 12.5683 },
  },
  {
    code: 'DJ',
    label: 'Djibouti',
    capital: 'Djibouti',
    phone: '253',
    location: { lat: 11.8251, lng: 42.5903 },
  },
  {
    code: 'DM',
    label: 'Dominica',
    capital: 'Roseau',
    phone: '1-767',
    location: { lat: 15.301, lng: -61.387 },
  },
  {
    code: 'DO',
    label: 'Dominican Republic',
    capital: 'Santo Domingo',
    phone: '1-809, 1-829, 1-849',
    location: { lat: 18.4861, lng: -69.9312 },
  },
  {
    code: 'EC',
    label: 'Ecuador',
    capital: 'Quito',
    phone: '593',
    location: { lat: -0.2295, lng: -78.5249 },
  },
  {
    code: 'EG',
    label: 'Egypt',
    capital: 'Cairo',
    phone: '20',
    location: { lat: 30.0444, lng: 31.2357 },
  },
  {
    code: 'SV',
    label: 'El Salvador',
    capital: 'San Salvador',
    phone: '503',
    location: { lat: 13.6929, lng: -89.2182 },
  },
  {
    code: 'GQ',
    label: 'Equatorial Guinea',
    capital: 'Malabo',
    phone: '240',
    location: { lat: 3.75, lng: 8.7832 },
  },
  {
    code: 'ER',
    label: 'Eritrea',
    capital: 'Asmara',
    phone: '291',
    location: { lat: 15.3229, lng: 38.925 },
  },
  {
    code: 'EE',
    label: 'Estonia',
    capital: 'Tallinn',
    phone: '372',
    location: { lat: 59.437, lng: 24.7536 },
  },
  {
    code: 'ET',
    label: 'Ethiopia',
    capital: 'Addis Ababa',
    phone: '251',
    location: { lat: 9.145, lng: 40.4897 },
  },
  {
    code: 'FK',
    label: 'Falkland Islands',
    capital: 'Stanley',
    phone: '500',
    location: { lat: -51.7963, lng: -59.5236 },
  },
  {
    code: 'FO',
    label: 'Faroe Islands',
    capital: 'Tórshavn',
    phone: '298',
    location: { lat: 61.8926, lng: -6.9118 },
  },
  {
    code: 'FJ',
    label: 'Fiji',
    capital: 'Suva',
    phone: '679',
    location: { lat: -18.124, lng: 178.45 },
  },
  {
    code: 'FI',
    label: 'Finland',
    capital: 'Helsinki',
    phone: '358',
    location: { lat: 60.1699, lng: 24.9384 },
  },
  {
    code: 'FR',
    label: 'France',
    capital: 'Paris',
    phone: '33',
    location: { lat: 48.8566, lng: 2.3522 },
  },
  {
    code: 'GF',
    label: 'French Guiana',
    capital: 'Cayenne',
    phone: '594',
    location: { lat: 4.922, lng: -52.3133 },
  },
  {
    code: 'PF',
    label: 'French Polynesia',
    capital: 'Papeete',
    phone: '689',
    location: { lat: -17.6797, lng: -149.4068 },
  },
  {
    code: 'TF',
    label: 'French Southern Territories',
    capital: 'Port-aux-Français',
    phone: '',
    location: { lat: -49.2804, lng: 69.3486 },
  },
  {
    code: 'GA',
    label: 'Gabon',
    capital: 'Libreville',
    phone: '241',
    location: { lat: 0.4162, lng: 9.4673 },
  },
  {
    code: 'GM',
    label: 'Gambia',
    capital: 'Banjul',
    phone: '220',
    location: { lat: 13.4432, lng: -15.3101 },
  },
  {
    code: 'GE',
    label: 'Georgia',
    capital: 'Tbilisi',
    phone: '995',
    location: { lat: 41.7151, lng: 44.8271 },
  },
  {
    code: 'DE',
    label: 'Germany',
    capital: 'Berlin',
    phone: '49',
    location: { lat: 52.52, lng: 13.405 },
  },
  {
    code: 'GH',
    label: 'Ghana',
    capital: 'Accra',
    phone: '233',
    location: { lat: 5.6037, lng: -0.187 },
  },
  {
    code: 'GI',
    label: 'Gibraltar',
    capital: 'Gibraltar',
    phone: '350',
    location: { lat: 36.1408, lng: -5.3536 },
  },
  {
    code: 'GR',
    label: 'Greece',
    capital: 'Athens',
    phone: '30',
    location: { lat: 37.9838, lng: 23.7275 },
  },
  {
    code: 'GL',
    label: 'Greenland',
    capital: 'Nuuk',
    phone: '299',
    location: { lat: 71.7069, lng: -42.6043 },
  },
  {
    code: 'GD',
    label: 'Grenada',
    capital: "St. George's",
    phone: '1-473',
    location: { lat: 12.056, lng: -61.7486 },
  },
  {
    code: 'GP',
    label: 'Guadeloupe',
    capital: 'Basse-Terre',
    phone: '590',
    location: { lat: 16.265, lng: -61.551 },
  },
  {
    code: 'GU',
    label: 'Guam',
    capital: 'Hagåtña',
    phone: '1-671',
    location: { lat: 13.4443, lng: 144.7937 },
  },
  {
    code: 'GT',
    label: 'Guatemala',
    capital: 'Guatemala City',
    phone: '502',
    location: { lat: 14.6349, lng: -90.5069 },
  },
  {
    code: 'GG',
    label: 'Guernsey',
    capital: 'St. Peter Port',
    phone: '44-1481',
    location: { lat: 49.4482, lng: -2.5895 },
  },
  {
    code: 'GN',
    label: 'Guinea',
    capital: 'Conakry',
    phone: '224',
    location: { lat: 9.6412, lng: -13.5784 },
  },
  {
    code: 'GW',
    label: 'Guinea-Bissau',
    capital: 'Bissau',
    phone: '245',
    location: { lat: 11.8037, lng: -15.1804 },
  },
  {
    code: 'GY',
    label: 'Guyana',
    capital: 'Georgetown',
    phone: '592',
    location: { lat: 6.8013, lng: -58.1551 },
  },
  {
    code: 'HT',
    label: 'Haiti',
    capital: 'Port-au-Prince',
    phone: '509',
    location: { lat: 18.5944, lng: -72.3074 },
  },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
    capital: '',
    phone: '672',
    location: { lat: -53.06, lng: 72.516 },
  },
  {
    code: 'HN',
    label: 'Honduras',
    capital: 'Tegucigalpa',
    phone: '504',
    location: { lat: 14.0728, lng: -87.217 },
  },
  {
    code: 'HK',
    label: 'Hong Kong',
    capital: 'City of Victoria',
    phone: '852',
    location: { lat: 22.3193, lng: 114.1694 },
  },
  {
    code: 'HU',
    label: 'Hungary',
    capital: 'Budapest',
    phone: '36',
    location: { lat: 47.4979, lng: 19.0402 },
  },
  {
    code: 'IS',
    label: 'Iceland',
    capital: 'Reykjavik',
    phone: '354',
    location: { lat: 64.1466, lng: -21.9426 },
  },
  {
    code: 'IN',
    label: 'India',
    capital: 'New Delhi',
    phone: '91',
    location: { lat: 28.6139, lng: 77.209 },
  },
  {
    code: 'ID',
    label: 'Indonesia',
    capital: 'Jakarta',
    phone: '62',
    location: { lat: -6.2088, lng: 106.8456 },
  },
  {
    code: 'IR',
    label: 'Iran',
    capital: 'Tehran',
    phone: '98',
    location: { lat: 35.6892, lng: 51.389 },
  },
  {
    code: 'IQ',
    label: 'Iraq',
    capital: 'Baghdad',
    phone: '964',
    location: { lat: 33.3152, lng: 44.3661 },
  },
  {
    code: 'IE',
    label: 'Ireland',
    capital: 'Dublin',
    phone: '353',
    location: { lat: 53.3498, lng: -6.2603 },
  },
  {
    code: 'IM',
    label: 'Isle of Man',
    capital: 'Douglas',
    phone: '44-1624',
    location: { lat: 54.2361, lng: -4.5481 },
  },
  {
    code: 'IL',
    label: 'Israel',
    capital: 'Jerusalem',
    phone: '972',
    location: { lat: 31.7683, lng: 35.2137 },
  },
  {
    code: 'IT',
    label: 'Italy',
    capital: 'Rome',
    phone: '39',
    location: { lat: 41.9028, lng: 12.4964 },
  },
  {
    code: 'CI',
    label: 'Ivory Coast',
    capital: 'Yamoussoukro',
    phone: '225',
    location: { lat: 6.8277, lng: -5.2893 },
  },
  {
    code: 'JM',
    label: 'Jamaica',
    capital: 'Kingston',
    phone: '1-876',
    location: { lat: 18.0179, lng: -76.8099 },
  },
  {
    code: 'JP',
    label: 'Japan',
    capital: 'Tokyo',
    phone: '81',
    location: { lat: 35.6895, lng: 139.6917 },
  },
  {
    code: 'JE',
    label: 'Jersey',
    capital: 'Saint Helier',
    phone: '44-1534',
    location: { lat: 49.2144, lng: -2.1312 },
  },
  {
    code: 'JO',
    label: 'Jordan',
    capital: 'Amman',
    phone: '962',
    location: { lat: 31.9497, lng: 35.932 },
  },
  {
    code: 'KZ',
    label: 'Kazakhstan',
    capital: 'Nur-Sultan',
    phone: '7',
    location: { lat: 51.1694, lng: 71.4491 },
  },
  {
    code: 'KE',
    label: 'Kenya',
    capital: 'Nairobi',
    phone: '254',
    location: { lat: -1.2864, lng: 36.8172 },
  },
  {
    code: 'KI',
    label: 'Kiribati',
    capital: 'South Tarawa',
    phone: '686',
    location: { lat: -0.5228, lng: 173.0477 },
  },
  {
    code: 'XK',
    label: 'Kosovo',
    capital: 'Pristina',
    phone: '383',
    location: { lat: 42.6629, lng: 21.1655 },
  },
  {
    code: 'KW',
    label: 'Kuwait',
    capital: 'Kuwait City',
    phone: '965',
    location: { lat: 29.3759, lng: 47.9774 },
  },
  {
    code: 'KG',
    label: 'Kyrgyzstan',
    capital: 'Bishkek',
    phone: '996',
    location: { lat: 42.8746, lng: 74.5698 },
  },
  {
    code: 'LA',
    label: 'Laos',
    capital: 'Vientiane',
    phone: '856',
    location: { lat: 17.9757, lng: 102.6331 },
  },
  {
    code: 'LV',
    label: 'Latvia',
    capital: 'Riga',
    phone: '371',
    location: { lat: 56.9496, lng: 24.1052 },
  },
  {
    code: 'LB',
    label: 'Lebanon',
    capital: 'Beirut',
    phone: '961',
    location: { lat: 33.8889, lng: 35.4944 },
  },
  {
    code: 'LS',
    label: 'Lesotho',
    capital: 'Maseru',
    phone: '266',
    location: { lat: -29.3167, lng: 27.4833 },
  },
  {
    code: 'LR',
    label: 'Liberia',
    capital: 'Monrovia',
    phone: '231',
    location: { lat: 6.3106, lng: -10.8047 },
  },
  {
    code: 'LY',
    label: 'Libya',
    capital: 'Tripoli',
    phone: '218',
    location: { lat: 32.8872, lng: 13.1913 },
  },
  {
    code: 'LI',
    label: 'Liechtenstein',
    capital: 'Vaduz',
    phone: '423',
    location: { lat: 47.1411, lng: 9.5215 },
  },
  {
    code: 'LT',
    label: 'Lithuania',
    capital: 'Vilnius',
    phone: '370',
    location: { lat: 54.6872, lng: 25.2797 },
  },
  {
    code: 'LU',
    label: 'Luxembourg',
    capital: 'Luxembourg',
    phone: '352',
    location: { lat: 49.61, lng: 6.1296 },
  },
  {
    code: 'MO',
    label: 'Macao',
    capital: '',
    phone: '853',
    location: { lat: 22.1667, lng: 113.55 },
  },
  {
    code: 'MK',
    label: 'Macedonia',
    capital: 'Skopje',
    phone: '389',
    location: { lat: 41.9973, lng: 21.428 },
  },
  {
    code: 'MG',
    label: 'Madagascar',
    capital: 'Antananarivo',
    phone: '261',
    location: { lat: -18.8792, lng: 47.5079 },
  },
  {
    code: 'MW',
    label: 'Malawi',
    capital: 'Lilongwe',
    phone: '265',
    location: { lat: -13.9626, lng: 33.7741 },
  },
  {
    code: 'MY',
    label: 'Malaysia',
    capital: 'Kuala Lumpur',
    phone: '60',
    location: { lat: 3.139, lng: 101.6869 },
  },
  {
    code: 'MV',
    label: 'Maldives',
    capital: 'Malé',
    phone: '960',
    location: { lat: 4.1755, lng: 73.5093 },
  },
  {
    code: 'ML',
    label: 'Mali',
    capital: 'Bamako',
    phone: '223',
    location: { lat: 12.6392, lng: -8.0024 },
  },
  {
    code: 'MT',
    label: 'Malta',
    capital: 'Valletta',
    phone: '356',
    location: { lat: 35.9042, lng: 14.5189 },
  },
  {
    code: 'MH',
    label: 'Marshall Islands',
    capital: 'Majuro',
    phone: '692',
    location: { lat: 7.1315, lng: 171.1845 },
  },
  {
    code: 'MQ',
    label: 'Martinique',
    capital: 'Fort-de-France',
    phone: '596',
    location: { lat: 14.6415, lng: -61.0242 },
  },
  {
    code: 'MR',
    label: 'Mauritania',
    capital: 'Nouakchott',
    phone: '222',
    location: { lat: 18.0731, lng: -15.9582 },
  },
  {
    code: 'MU',
    label: 'Mauritius',
    capital: 'Port Louis',
    phone: '230',
    location: { lat: -20.1654, lng: 57.4896 },
  },
  {
    code: 'YT',
    label: 'Mayotte',
    capital: 'Mamoudzou',
    phone: '262',
    location: { lat: -12.8275, lng: 45.1662 },
  },
  {
    code: 'MX',
    label: 'Mexico',
    capital: 'Mexico City',
    phone: '52',
    location: { lat: 19.4326, lng: -99.1332 },
  },
  {
    code: 'FM',
    label: 'Micronesia',
    capital: 'Palikir',
    phone: '691',
    location: { lat: 6.8874, lng: 158.215 },
  },
  {
    code: 'MD',
    label: 'Moldova',
    capital: 'Chișinău',
    phone: '373',
    location: { lat: 47.0104, lng: 28.8638 },
  },
  {
    code: 'MC',
    label: 'Monaco',
    capital: 'Monaco',
    phone: '377',
    location: { lat: 43.7384, lng: 7.4246 },
  },
  {
    code: 'MN',
    label: 'Mongolia',
    capital: 'Ulaanbaatar',
    phone: '976',
    location: { lat: 47.8864, lng: 106.9057 },
  },
  {
    code: 'ME',
    label: 'Montenegro',
    capital: 'Podgorica',
    phone: '382',
    location: { lat: 42.7087, lng: 19.3744 },
  },
  {
    code: 'MS',
    label: 'Poppins',
    capital: 'Plymouth',
    phone: '1-664',
    location: { lat: 16.7425, lng: -62.1874 },
  },
  {
    code: 'MA',
    label: 'Morocco',
    capital: 'Rabat',
    phone: '212',
    location: { lat: 33.9693, lng: -6.9273 },
  },
  {
    code: 'MZ',
    label: 'Mozambique',
    capital: 'Maputo',
    phone: '258',
    location: { lat: -25.9667, lng: 32.5804 },
  },
  {
    code: 'MM',
    label: 'Myanmar',
    capital: 'Naypyidaw',
    phone: '95',
    location: { lat: 21.9162, lng: 95.956 },
  },
  {
    code: 'NA',
    label: 'Namibia',
    capital: 'Windhoek',
    phone: '264',
    location: { lat: -22.559, lng: 17.0832 },
  },
  {
    code: 'NR',
    label: 'Nauru',
    capital: 'Yaren',
    phone: '674',
    location: { lat: -0.5228, lng: 166.9315 },
  },
  {
    code: 'NP',
    label: 'Nepal',
    capital: 'Kathmandu',
    phone: '977',
    location: { lat: 27.7172, lng: 85.324 },
  },
  {
    code: 'NL',
    label: 'Netherlands',
    capital: 'Amsterdam',
    phone: '31',
    location: { lat: 52.3702, lng: 4.8952 },
  },
  {
    code: 'NC',
    label: 'New Caledonia',
    capital: 'Nouméa',
    phone: '687',
    location: { lat: -22.2758, lng: 166.458 },
  },
  {
    code: 'NZ',
    label: 'New Zealand',
    capital: 'Wellington',
    phone: '64',
    location: { lat: -41.2865, lng: 174.7762 },
  },
  {
    code: 'NI',
    label: 'Nicaragua',
    capital: 'Managua',
    phone: '505',
    location: { lat: 12.1149, lng: -86.2362 },
  },
  {
    code: 'NE',
    label: 'Niger',
    capital: 'Niamey',
    phone: '227',
    location: { lat: 13.5116, lng: 2.1254 },
  },
  {
    code: 'NG',
    label: 'Nigeria',
    capital: 'Abuja',
    phone: '234',
    location: { lat: 9.0579, lng: 7.4951 },
  },
  {
    code: 'NU',
    label: 'Niue',
    capital: 'Alofi',
    phone: '683',
    location: { lat: -19.0544, lng: -169.8672 },
  },
  {
    code: 'NF',
    label: 'Norfolk Island',
    capital: 'Kingston',
    phone: '672',
    location: { lat: -29.0408, lng: 167.9547 },
  },
  {
    code: 'KP',
    label: 'North Korea',
    capital: 'Pyongyang',
    phone: '850',
    location: { lat: 39.0392, lng: 125.7625 },
  },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
    capital: 'Saipan',
    phone: '1-670',
    location: { lat: 15.0979, lng: 145.6739 },
  },
  {
    code: 'NO',
    label: 'Norway',
    capital: 'Oslo',
    phone: '47',
    location: { lat: 59.9139, lng: 10.7522 },
  },
  {
    code: 'OM',
    label: 'Oman',
    capital: 'Muscat',
    phone: '968',
    location: { lat: 23.6105, lng: 58.54 },
  },
  {
    code: 'PK',
    label: 'Pakistan',
    capital: 'Islamabad',
    phone: '92',
    location: { lat: 33.6844, lng: 73.0479 },
  },
  {
    code: 'PW',
    label: 'Palau',
    capital: 'Ngerulmud',
    phone: '680',
    location: { lat: 7.5149, lng: 134.5825 },
  },
  {
    code: 'PS',
    label: 'Palestinian Territory',
    capital: 'East Jerusalem',
    phone: '970',
    location: { lat: 31.9522, lng: 35.2332 },
  },
  {
    code: 'PA',
    label: 'Panama',
    capital: 'Panama City',
    phone: '507',
    location: { lat: 8.9824, lng: -79.5199 },
  },
  {
    code: 'PG',
    label: 'Papua New Guinea',
    capital: 'Port Moresby',
    phone: '675',
    location: { lat: -9.4438, lng: 147.1803 },
  },
  {
    code: 'PY',
    label: 'Paraguay',
    capital: 'Asunción',
    phone: '595',
    location: { lat: -25.2637, lng: -57.5759 },
  },
  {
    code: 'PE',
    label: 'Peru',
    capital: 'Lima',
    phone: '51',
    location: { lat: -12.0464, lng: -77.0428 },
  },
  {
    code: 'PH',
    label: 'Philippines',
    capital: 'Manila',
    phone: '63',
    location: { lat: 14.5995, lng: 120.9842 },
  },
  {
    code: 'PN',
    label: 'Pitcairn',
    capital: 'Adamstown',
    phone: '870',
    location: { lat: -24.3768, lng: -128.3244 },
  },
  {
    code: 'PL',
    label: 'Poland',
    capital: 'Warsaw',
    phone: '48',
    location: { lat: 52.2297, lng: 21.0122 },
  },
  {
    code: 'PT',
    label: 'Portugal',
    capital: 'Lisbon',
    phone: '351',
    location: { lat: 38.7223, lng: -9.1393 },
  },
  {
    code: 'PR',
    label: 'Puerto Rico',
    capital: 'San Juan',
    phone: '1-787, 1-939',
    location: { lat: 18.4655, lng: -66.1057 },
  },
  {
    code: 'QA',
    label: 'Qatar',
    capital: 'Doha',
    phone: '974',
    location: { lat: 25.2854, lng: 51.531 },
  },
  {
    code: 'RE',
    label: 'Réunion',
    capital: 'Saint-Denis',
    phone: '262',
    location: { lat: -21.1151, lng: 55.5364 },
  },
  {
    code: 'RO',
    label: 'Romania',
    capital: 'Bucharest',
    phone: '40',
    location: { lat: 44.4268, lng: 26.1025 },
  },
  {
    code: 'RU',
    label: 'Russia',
    capital: 'Moscow',
    phone: '7',
    location: { lat: 55.7558, lng: 37.6176 },
  },
  {
    code: 'RW',
    label: 'Rwanda',
    capital: 'Kigali',
    phone: '250',
    location: { lat: -1.9706, lng: 30.1044 },
  },
  {
    code: 'BL',
    label: 'Saint Barthélemy',
    capital: 'Gustavia',
    phone: '590',
    location: { lat: 17.9, lng: -62.8333 },
  },
  {
    code: 'SH',
    label: 'Saint Helena',
    capital: 'Jamestown',
    phone: '290',
    location: { lat: -15.9654, lng: -5.7089 },
  },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
    capital: 'Basseterre',
    phone: '1-869',
    location: { lat: 17.3578, lng: -62.783 },
  },
  {
    code: 'LC',
    label: 'Saint Lucia',
    capital: 'Castries',
    phone: '1-758',
    location: { lat: 13.9094, lng: -60.9789 },
  },
  {
    code: 'MF',
    label: 'Saint Martin (French part)',
    capital: 'Marigot',
    phone: '590',
    location: { lat: 18.0708, lng: -63.0501 },
  },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
    capital: 'Saint-Pierre',
    phone: '508',
    location: { lat: 46.7804, lng: -56.1852 },
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    capital: 'Kingstown',
    phone: '1-784',
    location: { lat: 13.2528, lng: -61.197 },
  },
  {
    code: 'WS',
    label: 'Samoa',
    capital: 'Apia',
    phone: '685',
    location: { lat: -13.759, lng: -172.1046 },
  },
  {
    code: 'SM',
    label: 'San Marino',
    capital: 'San Marino',
    phone: '378',
    location: { lat: 43.9424, lng: 12.4578 },
  },
  {
    code: 'ST',
    label: 'São Tomé and Príncipe',
    capital: 'São Tomé',
    phone: '239',
    location: { lat: 0.1864, lng: 6.6131 },
  },
  {
    code: 'SA',
    label: 'Saudi Arabia',
    capital: 'Riyadh',
    phone: '966',
    location: { lat: 24.7136, lng: 46.6753 },
  },
  {
    code: 'SN',
    label: 'Senegal',
    capital: 'Dakar',
    phone: '221',
    location: { lat: 14.6928, lng: -17.4467 },
  },
  {
    code: 'RS',
    label: 'Serbia',
    capital: 'Belgrade',
    phone: '381',
    location: { lat: 44.7866, lng: 20.4489 },
  },
  {
    code: 'SC',
    label: 'Seychelles',
    capital: 'Victoria',
    phone: '248',
    location: { lat: -4.6796, lng: 55.492 },
  },
  {
    code: 'SL',
    label: 'Sierra Leone',
    capital: 'Freetown',
    phone: '232',
    location: { lat: 8.4653, lng: -13.2312 },
  },
  {
    code: 'SG',
    label: 'Singapore',
    capital: 'Singapore',
    phone: '65',
    location: { lat: 1.3521, lng: 103.8198 },
  },
  {
    code: 'SX',
    label: 'Sint Maarten',
    capital: 'Philipsburg',
    phone: '1-721',
    location: { lat: 18.0341, lng: -63.0686 },
  },
  {
    code: 'SK',
    label: 'Slovakia',
    capital: 'Bratislava',
    phone: '421',
    location: { lat: 48.1486, lng: 17.1077 },
  },
  {
    code: 'SI',
    label: 'Slovenia',
    capital: 'Ljubljana',
    phone: '386',
    location: { lat: 46.0569, lng: 14.5058 },
  },
  {
    code: 'SB',
    label: 'Solomon Islands',
    capital: 'Honiara',
    phone: '677',
    location: { lat: -9.4339, lng: 159.955 },
  },
  {
    code: 'SO',
    label: 'Somalia',
    capital: 'Mogadishu',
    phone: '252',
    location: { lat: 2.0469, lng: 45.3182 },
  },
  {
    code: 'ZA',
    label: 'South Africa',
    capital: 'Pretoria',
    phone: '27',
    location: { lat: -25.7461, lng: 28.1881 },
  },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    capital: 'Grytviken',
    phone: '',
    location: { lat: -54.4296, lng: -36.5879 },
  },
  {
    code: 'KR',
    label: 'South Korea',
    capital: 'Seoul',
    phone: '82',
    location: { lat: 37.5665, lng: 126.978 },
  },
  {
    code: 'SS',
    label: 'South Sudan',
    capital: 'Juba',
    phone: '211',
    location: { lat: 4.8594, lng: 31.5713 },
  },
  {
    code: 'ES',
    label: 'Spain',
    capital: 'Madrid',
    phone: '34',
    location: { lat: 40.4168, lng: -3.7038 },
  },
  {
    code: 'LK',
    label: 'Sri Lanka',
    capital: 'Colombo',
    phone: '94',
    location: { lat: 6.9271, lng: 79.8612 },
  },
  {
    code: 'SD',
    label: 'Sudan',
    capital: 'Khartoum',
    phone: '249',
    location: { lat: 15.5007, lng: 32.5599 },
  },
  {
    code: 'SR',
    label: 'Suriname',
    capital: 'Paramaribo',
    phone: '597',
    location: { lat: 5.852, lng: -55.2038 },
  },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
    capital: 'Longyearbyen',
    phone: '47',
    location: { lat: 77.5536, lng: 23.6703 },
  },
  {
    code: 'SZ',
    label: 'Swaziland',
    capital: 'Mbabane',
    phone: '268',
    location: { lat: -26.5225, lng: 31.4659 },
  },
  {
    code: 'SE',
    label: 'Sweden',
    capital: 'Stockholm',
    phone: '46',
    location: { lat: 59.3293, lng: 18.0686 },
  },
  {
    code: 'CH',
    label: 'Switzerland',
    capital: 'Bern',
    phone: '41',
    location: { lat: 46.948, lng: 7.4474 },
  },
  {
    code: 'SY',
    label: 'Syria',
    capital: 'Damascus',
    phone: '963',
    location: { lat: 33.5138, lng: 36.2765 },
  },
  {
    code: 'TW',
    label: 'Taiwan',
    capital: 'Taipei',
    phone: '886',
    location: { lat: 25.032, lng: 121.5654 },
  },
  {
    code: 'TJ',
    label: 'Tajikistan',
    capital: 'Dushanbe',
    phone: '992',
    location: { lat: 38.861, lng: 71.2761 },
  },
  {
    code: 'TZ',
    label: 'Tanzania',
    capital: 'Dodoma',
    phone: '255',
    location: { lat: -6.369, lng: 34.8888 },
  },
  {
    code: 'TH',
    label: 'Thailand',
    capital: 'Bangkok',
    phone: '66',
    location: { lat: 13.7563, lng: 100.5018 },
  },
  {
    code: 'TG',
    label: 'Togo',
    capital: 'Lomé',
    phone: '228',
    location: { lat: 6.1319, lng: 1.2225 },
  },
  {
    code: 'TK',
    label: 'Tokelau',
    capital: 'Fakaofo',
    phone: '690',
    location: { lat: -9.2, lng: -171.8484 },
  },
  {
    code: 'TO',
    label: 'Tonga',
    capital: 'Nukuʻalofa',
    phone: '676',
    location: { lat: -21.1789, lng: -175.1982 },
  },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
    capital: 'Port of Spain',
    phone: '1-868',
    location: { lat: 10.6918, lng: -61.2225 },
  },
  {
    code: 'TN',
    label: 'Tunisia',
    capital: 'Tunis',
    phone: '216',
    location: { lat: 33.8869, lng: 9.5375 },
  },
  {
    code: 'TR',
    label: 'Turkey',
    capital: 'Ankara',
    phone: '90',
    location: { lat: 39.9334, lng: 32.8597 },
  },
  {
    code: 'TM',
    label: 'Turkmenistan',
    capital: 'Ashgabat',
    phone: '993',
    location: { lat: 37.9601, lng: 58.3261 },
  },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
    capital: 'Cockburn Town',
    phone: '1-649',
    location: { lat: 21.694, lng: -71.7979 },
  },
  {
    code: 'TV',
    label: 'Tuvalu',
    capital: 'Funafuti',
    phone: '688',
    location: { lat: -7.1095, lng: 177.6493 },
  },
  {
    code: 'UG',
    label: 'Uganda',
    capital: 'Kampala',
    phone: '256',
    location: { lat: 0.3476, lng: 32.5825 },
  },
  {
    code: 'UA',
    label: 'Ukraine',
    capital: 'Kyiv',
    phone: '380',
    location: { lat: 50.4501, lng: 30.5234 },
  },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    capital: 'Abu Dhabi',
    phone: '971',
    location: { lat: 24.4667, lng: 54.3667 },
  },
  {
    code: 'GB',
    label: 'United Kingdom',
    capital: 'London',
    phone: '44',
    location: { lat: 51.5074, lng: -0.1278 },
  },
  {
    code: 'US',
    label: 'United States',
    capital: 'Washington, D.C.',
    phone: '1',
    location: { lat: 38.9072, lng: -77.0369 },
  },
  {
    code: 'UY',
    label: 'Uruguay',
    capital: 'Montevideo',
    phone: '598',
    location: { lat: -34.9011, lng: -56.1645 },
  },
  {
    code: 'UZ',
    label: 'Uzbekistan',
    capital: 'Tashkent',
    phone: '998',
    location: { lat: 41.3775, lng: 64.5853 },
  },
  {
    code: 'VU',
    label: 'Vanuatu',
    capital: 'Port Vila',
    phone: '678',
    location: { lat: -17.7404, lng: 168.3227 },
  },
  {
    code: 'VA',
    label: 'Vatican City',
    capital: 'Vatican City',
    phone: '379',
    location: { lat: 41.9029, lng: 12.4534 },
  },
  {
    code: 'VE',
    label: 'Venezuela',
    capital: 'Caracas',
    phone: '58',
    location: { lat: 10.4806, lng: -66.9036 },
  },
  {
    code: 'VN',
    label: 'Vietnam',
    capital: 'Hanoi',
    phone: '84',
    location: { lat: 21.0285, lng: 105.8542 },
  },
  {
    code: 'WF',
    label: 'Wallis and Futuna',
    capital: 'Mata-Utu',
    phone: '681',
    location: { lat: -13.7687, lng: -177.1561 },
  },
  {
    code: 'EH',
    label: 'Western Sahara',
    capital: 'El Aaiún',
    phone: '212',
    location: { lat: 24.2155, lng: -12.8858 },
  },
  {
    code: 'YE',
    label: 'Yemen',
    capital: "Sana'a",
    phone: '967',
    location: { lat: 15.3695, lng: 44.191 },
  },
  {
    code: 'ZM',
    label: 'Zambia',
    capital: 'Lusaka',
    phone: '260',
    location: { lat: -15.4167, lng: 28.2833 },
  },
  {
    code: 'ZW',
    label: 'Zimbabwe',
    capital: 'Harare',
    phone: '263',
    location: { lat: -17.8292, lng: 31.0522 },
  },
];
